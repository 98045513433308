import { useEffect } from 'react'
import useSEO from '../hooks/useSEO.jsx';
import styled from 'styled-components';
import ReactTypingEffect from 'react-typing-effect';
import {AiFillPhone, AiOutlineMail, AiFillGithub, AiOutlineInstagram} from 'react-icons/ai'
import {IoLogoBitbucket} from 'react-icons/io'



export default function HomeScreen(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const title = "Home"
      const description = "Web Oficial de Mario P Developer."
      useSEO({title, description});

    return(<>
            <Home />
            <Projects />
            <Contact />
    </>);
};


const StyledIntroWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > div{
        width: 50%;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        flex-wrap: no-wrap;
        bacground-color: red;

        & > div{
            width: 100vw;
        }
    }

    


`




function Home(props){
    return(<>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', width:'100vw', height:'100vh', maxWidth:'100%', background:'var(--oxford-blue-dark-color)'}}>
            <StyledIntroWrapper>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <h1 style={{fontFamily:'sans-serif', fontSize:'var(--h1-font-size)', color:'white'}}>Hi, I'm Mario, <br /> I'm a <ReactTypingEffect text={["Developer.", "Coder.", "App creator."]}/></h1>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', maxWidth:'100vw', overflow:'hidden'}}>
                    <div style={{width: '100%', height:'auto', position:'relative', display:'flex', justifyContent:'center', alignItems:'center', minWidth:'530px', maxWidth:'100vw'}}>
                        <div style={{width:'35vw', height:'300px', backgroundColor:'var(--roman-silver-color)', opacity:'.15', backdropFilter:'blur(5px)', position:'absolute', zIndex:'0', top:'150px', left:'80px'}}></div>
                        <div style={{width:'35vw', height:'300px', backgroundColor:'var(--usafa-blue-color)', opacity:'.15', backdropFilter:'blur(5px)', position:'absolute', zIndex:'0', top:'-50px', left:'-40px'}}></div>
                        <img alt="" src="img/img.png" style={{zIndex:'3', width:'fit-content', height:'auto', maxWidth: 'vw'}}></img>
                    </div>
                </div>
            </StyledIntroWrapper>
            <div style={{display: 'flex', flexDirection:'row', flexWrap:'wrap', justifyContent: 'center', alignItems: 'center', marginBottom:'2rem'}}>
                <li style={{display:'flex', color: 'white', justifyContent:'space-around', width:'30vw', marginTop:'.5rem'}}>
                    <a style={{color:'white', textDecoration:'none'}} href="https://github.com/MarioP-Dev"><AiFillGithub style={{width:'1.5rem', height:'auto'}} /></a>
                    <a style={{color:'white', textDecoration:'none'}} href="https://bitbucket.org/MarioP-Dev"><IoLogoBitbucket style={{width:'1.5rem', height:'auto'}} /></a>
                    <a style={{color:'white', textDecoration:'none'}} href="https://instagram.com/mariopperezz_/"><AiOutlineInstagram style={{width:'1.5rem', height:'auto'}} /></a>
                </li>
            </div>
        </div>
    </>);
}

const Card = styled.div`
    border-radius: 15px;
    padding: 2rem;
    width: 90%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#f2f2f2;
    box-shadow: 0 0 20px 8px #d0d0d0;
    margin: .5rem;
`

const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    transition-duration: .5s;
    width: 80%;
    background-color: var(--roman-silver-color);
    border: .5px solid var(--roman-silver-color);
    color: white;
    text-decoration: none;

    &:hover {
        
        background-color: transparent;
        color: var(--roman-silver-color);
        
    }
`

const TextList = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    margin:  1rem 0;

    & > span {
        padding: .2rem .4rem .2rem .4rem;
        background-color: var( --true-blue-color);
        color: white;
        border-radius: 5px;
    }
`

function Projects(props){
    return(<>
        <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem', minWidth:'90vw'}}>
            <h1>Latests Projects</h1>
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-around', alignItems: 'center', width:'90%'}}>       
                <Card>
                    <div style={{width: '90%', height:'100px'}}>
                        <img alt="QueMeVendes Logo" style={{objectFit: 'contain', width:'100%', height:'100%'}} src="http://storage.xiarapina.com/corp/quemevendes/LogoBlack%402x.png"></img>
                    </div>
                    <p>QueMeVendes.es is a fully functionl web store, stock manager and TPV based on the structure Frontend Backend.</p>
                    <TextList>
                        <span style={{backgroundColor:'var(--oxford-blue-color)', color: 'white'}}>FullStack Developer</span>
                    </TextList>
                    <TextList>
                        <span>React</span>
                        <span>NodeJS</span>
                    </TextList>
                    <Button href="https://quemevendes.es"><span>View project</span></Button>
                </Card>

                <Card>
                    <div style={{width: '90%', height:'100px'}}>
                        <img alt="Ángeles Miranda Logo" style={{objectFit: 'contain', width:'100%', height:'100%'}} src="https://angelesmiranda.com/wp-content/uploads/2021/05/AM_Logo.png"></img>
                    </div>
                    <p>Online store of the Asturian manufacturer specialized in the design and manufacture of baby stroller bags Ángeles Miranda using WooCommerce.</p>
                    <TextList>
                        <span style={{backgroundColor:'var(--oxford-blue-color)', color: 'white'}}>System administrator</span>
                        <span style={{backgroundColor:'var(--oxford-blue-color)', color: 'white'}}>Dev-Ops</span>
                        
                    </TextList>
                    <TextList>
                        <span>WooCommerce</span>
                        <span>RedSys</span>
                    </TextList>
                    <Button href="https://angelesmiranda.com"><span>View project</span></Button>
                </Card>

                <Card>
                    <div style={{width: '90%', height:'100px'}}>
                        <img alt="La Xiarapina Logo" style={{objectFit: 'contain', width:'100%', height:'100%', filter:'invert(100%)'}} src="https://xiarapina.com/images/logo_white.png"></img>
                    </div>
                    <p>Website of "La Xiarapina", restored traditional Asturian accommodations. Completely done with HTML, CSS, and JS.</p>
                    <TextList>
                        <span style={{backgroundColor:'var(--oxford-blue-color)', color: 'white'}}>FullStack Developer</span>
                    </TextList>
                    <TextList>
                        <span>HTML</span>
                        <span>CSS</span>
                        <span>JS</span>
                    </TextList>
                    <Button href="https://xiarapina.com"><span>View project</span></Button>
                </Card>
            </div>
            
        </div>
    </>);
}

const ContactCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width:  100%;
    margin: 3rem;

    @media (max-width: 380px) {
        flex-direction: column;
        div:nth-child(2){
            display: none;
        }
    }

`


function Contact(props){
    return(<>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{marginBottom: '-10px'}}>
            <path fill="var(--oxford-blue-light-color)" fill-opacity="1" d="M0,256L34.3,240C68.6,224,137,192,206,154.7C274.3,117,343,75,411,96C480,117,549,203,617,229.3C685.7,256,754,224,823,224C891.4,224,960,256,1029,240C1097.1,224,1166,160,1234,122.7C1302.9,85,1371,75,1406,69.3L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
        </svg>
        <div style={{display: 'flex', backgroundColor:'var(--oxford-blue-light-color)', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width:'100vw', maxWidth: '100%', overflow:'hidden'}}>
            <ContactCard>
                <div>
                    <img alt="Mario P Dev Profile" style={{borderRadius: '50%', height:'fit-content', maxHeight:'180px', width:'auto'}} src="https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/61436f93c2fda2006a8be521/1f789ab3-16b3-4951-971e-6839bb5bc85b/128?s=128"></img>
                </div>
                <div style={{minHeight: '5rem', margin:'0 0 0 .5rem', borderLeft: '3px white solid'}}></div>
                <div>
                    <ul style={{listStyle:'none', margin:'0'}}>
                        <li><a style={{ display:'flex', color: 'white', justifyContent:'flex-start', alignItems:'center', marginTop:'.5rem', textDecoration: 'none', fontFamily:'sans-serif'}} href="tel:+34633346053"><AiFillPhone style={{width:'1.5rem', height:'auto', marginRight:'.2rem'}} /> +34 633346053 </a></li>
                        <li><a style={{ display:'flex', color: 'white', justifyContent:'flex-start', alignItems:'center', marginTop:'.5rem', textDecoration: 'none', fontFamily:'sans-serif'}} href="mailto:mario@mariopdev.com"><AiOutlineMail style={{width:'1.5rem', height:'auto', marginRight:'.2rem'}} /> mario@mariopdev.com</a></li>
                        <li style={{display:'flex', color: 'white', justifyContent:'space-around', width:'100%', marginTop:'.5rem'}}>
                            <a style={{color:'white', textDecoration:'none'}} href="https://github.com/MarioP-Dev"><AiFillGithub style={{width:'1.5rem', height:'auto'}} /></a>
                            <a style={{color:'white', textDecoration:'none'}} href="https://bitbucket.org/MarioP-Dev"><IoLogoBitbucket style={{width:'1.5rem', height:'auto'}} /></a>
                            <a style={{color:'white', textDecoration:'none'}} href="https://instagram.com/mariopperezz_/"><AiOutlineInstagram style={{width:'1.5rem', height:'auto'}} /></a>
                        </li>
                    </ul>
                </div>
            </ContactCard>
        </div>
    </>);
}
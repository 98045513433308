import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Homescreen from './screens/homeScreen';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Homescreen} exact></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
